import { useState, useEffect } from 'react';

import styled from 'styled-components/macro';
import QRContainer from './components/QRContainer';
import { useParams } from 'react-router-dom';
import RoomsList from './components/RoomsList';
import Api from '../../utils/Api';

export default function RoomsPage() {
  const [logo, setLogo] = useState(null);
  const [color, setColor] = useState(null);
  const [uniqueId, setUniqueId] = useState(null);
  const [rooms, setRooms] = useState();
  const [selectedRoom, setSelectedRoom] = useState();
  const { propertyId } = useParams();
  const [selectedRoomName, setSelectedRoomName] = useState();
  const [loading, setLoading] = useState(true);

  const getHotelLogoAndColor = async () => {
    try {
      const { data } = await Api.get(`/hotel/information?propertyId=${propertyId}`, {
        headers: {
          token: localStorage.getItem('token')
        }
      });
      setUniqueId(data.uniqueId);
      setLogo(data.logo);
      setColor(data.color);
    } catch (err) {
      console.log(err);
    }
  };

  const getRooms = async () => {
    try {
      const { data } = await Api.get(`/hotel/${propertyId}/rooms`, {
        headers: {
          token: localStorage.getItem('token')
        }
      });
      setRooms(data);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getRooms().then(() => console.log('Rooms Fetched'));
    getHotelLogoAndColor().then(() => console.log('Hotel Information fetched'));
    setLoading(false);
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  } else {
    return (
      <Wrapper>
        <div className="list">
          <RoomsList
            uniqueId={uniqueId}
            logo={logo}
            color={color}
            rooms={rooms}
            selectedRoom={selectedRoom}
            setSelectedRoom={setSelectedRoom}
            setSelectedRoomName={setSelectedRoomName}
          />
        </div>
        {selectedRoom && (
          <QRContainer
            uniqueId={uniqueId}
            propertyId={selectedRoom.property.id}
            selectedItemId={selectedRoom.id}
            selectedItemName={selectedRoomName}
            hotelImage={logo}
            color={color}
          />
        )}
      </Wrapper>
    );
  }
}

//      STYLES

const Wrapper = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & div.list {
    width: 65%;
  }
`;
