import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    transition: background-Color 0.5s, color 0.5s;

    /* border: 1px dotted red; */
  }
  button {
    transition: background-Color 0.5s, color 0.5s;
  }
`;

export const Theme = {
  pjPink: '#FFF5EB',
  pjBlack: '#1D1817',
  pjDisabled: '#828282',
  pjWhite: '#FBFAFA',
  pjOrange: '#EE7B30',
  pjSuccess: '#1BB250',
  pjFailed: '#DC4639',
  heyJackFont: "'Hey Jack', sans-serif"
};
