import styled from 'styled-components/macro';

export const Button = styled.button`
  margin: 0.3em 0;
  width: 23.5%;
  min-width: 100px;
  padding: 0.7rem 0;
  border: none;
  border-radius: 0.6vw;
  color: ${(props) => (props.selected ? props.theme.pjWhite : props.theme.pjBlack)};
  font-weight: ${(props) => (props.selected ? 'bold' : 'normal')}
  font-size: 24px;
  background-color: ${(props) => (props.selected ? props.theme.pjOrange : props.theme.pjWhite)};
  text-transform: capitalize;
  box-shadow: 0 4px 5px #ddd;
`;

export const SecondaryButton = styled(Button)`
  width: 90%;
  color: ${(props) => (props.color ? props.color : props.theme.pjOrange)};
  background-color: transparent;
  border: 1px solid ${(props) => (props.color ? props.color : props.theme.pjOrange)};
  transition: all 0.3s;
  &:hover {
    background-color: ${(props) => (props.color ? props.color : props.theme.pjOrange)};
    color: ${(props) => props.theme.pjWhite};
    border-color: #ffffff00;
  }
`;
export const OrangeButton = styled(Button)`
  width: 15%;
  letter-spacing: 1px;
  padding: 5px;
  padding-top: 7px;
  height: 20%;
  color: ${(props) => props.theme.pjWhite};
  font-size: 17px;
  font-weight: bold;
  background-color: ${(props) => props.theme.pjOrange};
`;

export const WhiteButton = styled.button`
  margin: 0.3em 1vw;
  padding: 10px 30px;
  border: none;
  border-radius: 1.2vw;
  color: ${(props) => (!props.disabled ? props.theme.pjBlack : props.theme.pjWhite)};
  font-size: clamp(16px, 1.6vw, 1.6vw);
  background-color: ${(props) => (props.disabled ? props.theme.pjDisabled : props.theme.pjWhite)};
  box-shadow: 0 4px 5px #ddd;
  &:hover {
    background-color: ${(props) => !props.disabled && props.theme.pjOrange};
    color: ${(props) => !props.disabled && props.theme.pjWhite};
  }
`;
