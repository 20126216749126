import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ component }) => {
  const auth = localStorage.getItem('token') ?? false;

  if (auth) {
    return component;
  } else {
    return <Navigate replace to="/" />;
  }
};

export default ProtectedRoute;
