import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import showIcon from '../../../assets/Show.svg';
import { NotificationManager } from 'react-notifications';
import Api from '../../../utils/Api';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const InputWrapper = styled.div`
  width: 100%;
  border-radius: 10px;
`;

const InputField = styled(Field)`
  height: 67px;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #ccc;
  font-size: 16px;
  width: 100%;
  margin-bottom: 3%;
`;

const FirstIconWrapper = styled.div`
  position: absolute;
  top: 25%;
  right: 5%;
  transform: translateY(-50%);
  cursor: pointer;
`;

const SecondIconWrapper = styled.div`
  position: absolute;
  top: 24%;
  right: 5%;
  transform: translateY(-50%);
  cursor: pointer;
`;

const ErrorText = styled.div`
  color: red;
  margin-top: 5px;
`;

const SubmitButtonContainer = styled.div`
  display: flex;
  justify-content: left;
  margin-left: 20%;
  margin-top: -5%;
`;

const SubmitButton = styled.button`
  background-color: ${(props) => (props.disabled ? 'var(--pj-disabled)' : 'var(--pj-orange)')};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  height: 44px;
  padding: 10px;
  border-radius: 10px;
  border: none;
  color: white;
  font-size: 16px;
  width: 50%;
`;

const AgreementText = styled.div`
  margin-bottom: 10%;
  margin-left: 2%;
  font-size: 14px;
  font-weight: 100;
  line-height: 2;

  a {
    color: var(--pj-orange);
  }
`;
const FieldCheckbox = styled.span`
  margin-top: 1.4%;
  margin-left: 20%;
`;

const ErrorMessageWrapper = styled.div`
  color: red;
`;

const RegistrationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email address').required('Email is required'),
  password: Yup.string()
    .required('Please Enter your password')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&.*])(?=.{8,})/,
      'Must Contain Minimum 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),

  termsAndConditions: Yup.boolean().oneOf([true], 'Please accept the terms and conditions')
});

const RegistrationForm = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const initialValues = {
    email: '',
    password: '',
    confirmPassword: ''
  };

  const handleSubmit = async (values) => {
    const data = {
      email: values.email,
      password: values.password
    };

    try {
      const res = await Api.post('/auth/register', data);
      if (res.status === 200 || res.status === 201) {
        navigate('/registration-success');
      } else {
        NotificationManager.error(res?.response?.data);
      }
    } catch (err) {
      console.log('Unknown error: ', err);
      NotificationManager.error(err?.response?.data);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={RegistrationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, isValid, touched }) => {
        const formIsValid = isValid && Object.keys(touched).length > 0;

        return (
          <FormContainer>
            <Form
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '5%',
                width: '100%',
                gap: '4%'
              }}
            >
              <div style={{ flex: '1' }}>
                <InputWrapper>
                  <Field name="email" type="email" as={InputField} placeholder="Email" />
                  <ErrorMessage name="email" component={ErrorMessageWrapper} />
                  <div style={{ position: 'relative' }}>
                    <Field
                      name="password"
                      type={showPassword ? 'text' : 'password'}
                      as={InputField}
                      placeholder="Password"
                    />
                    <FirstIconWrapper onClick={togglePasswordVisibility}>
                      <img
                        style={{ marginTop: '2em' }}
                        onClick={togglePasswordVisibility}
                        src={showIcon}
                        alt="arrow-right"
                      />
                    </FirstIconWrapper>
                    <ErrorMessage name="password" component={ErrorText} />
                  </div>
                  <div style={{ position: 'relative' }}>
                    <Field
                      name="confirmPassword"
                      type={showPassword ? 'text' : 'password'}
                      as={InputField}
                      placeholder="Confirm Password"
                    />
                    <SecondIconWrapper onClick={togglePasswordVisibility}>
                      <img
                        style={{ marginTop: '2em' }}
                        onClick={togglePasswordVisibility}
                        src={showIcon}
                        alt="arrow-right"
                      />
                    </SecondIconWrapper>
                    <ErrorMessage name="confirmPassword" component={ErrorMessageWrapper} />
                  </div>
                </InputWrapper>
              </div>

              <div style={{ flex: '1' }}>
                <div align="left" style={{ display: 'flex' }}>
                  <FieldCheckbox>
                    <Field
                      onClick={() => setChecked(!checked)}
                      type="checkbox"
                      name="termsAndConditions"
                    />
                  </FieldCheckbox>
                  <AgreementText>
                    I hereby agree to{' '}
                    <a href="http://www.payjim.de/agb">payjim's terms and conditions.</a>
                    <div>
                      <span>
                        For any concerns or further questions, please contact our support:{' '}
                      </span>
                      <span style={{ textDecoration: 'underline' }}>support@payjim.de</span>
                    </div>
                  </AgreementText>
                </div>
                <SubmitButtonContainer>
                  <SubmitButton type="submit" disabled={isSubmitting || !formIsValid || !checked}>
                    Register an account
                  </SubmitButton>
                </SubmitButtonContainer>
              </div>
            </Form>
          </FormContainer>
        );
      }}
    </Formik>
  );
};

export default RegistrationForm;
