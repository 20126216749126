export const TESS_ARTICLES = process.env.REACT_APP_HTESS_ARTICLES;
export const TESS_TABLES = process.env.REACT_APP_HTESS_TABLES;
export const TESS_API_KEY = process.env.REACT_APP_HTESS_API_KEY;

// PayJim API | Production

// export const PJ_API = process.env.REACT_APP_PJ_API;
// export const PJ_APP = process.env.REACT_APP_PJ_APP;

// PayJim API | Local

// export const PJ_API = process.env.REACT_APP_PJ_API_LOCAL
// export const PJ_APP = process.env.REACT_APP_PJ_APP;

// PayJim API | Staging

export const PJ_API = process.env.REACT_APP_PJ_API_STAGING;
export const PJ_HOTEL_API = process.env.REACT_APP_PJ_API_HOTEL;
export const PJ_APP = process.env.REACT_APP_PJ_APP_STAGING;

// PayJim API | Staging v2

// export const PJ_API = process.env.REACT_APP_PJ_API_STAGING_V2;
// export const PJ_APP = process.env.REACT_APP_PJ_APP_STAGING;
