import eye from '../../../assets/eye-icon.png';
import styled from 'styled-components/macro';

export default function RoomItem({
  room,
  selectedRoom,
  setSelectedRoom,
  setSelectedRoomName,
  color
}) {
  const handleView = () => {
    setSelectedRoom(room);
    setSelectedRoomName(room.name);
  };

  return (
    <>
      <RoomWrapper
        style={{
          border: `2px solid ${selectedRoom === room ? color || '#EE7B30' : 'transparent'}`,
          backgroundColor: '#FBFAFA'
        }}>
        <Header>
          <div className="id">{!isNaN(room.name) ? room.id : room.name}</div>
          <div>{!isNaN(room.name) && room.name}</div>
          <div>{room.description && room.description}</div>
          <div>
            <img onClick={handleView} src={eye} width="24px" alt="show the QR Code" />
          </div>
        </Header>
      </RoomWrapper>
      {/* )} */}
    </>
  );
}

const RoomWrapper = styled.div`
  background-color: ${(props) => props.theme.pjWhite};
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  cursor: default;
  padding: 1.5vw 0;
  margin-bottom: 1.5vw;
`;

const Header = styled.div`
  display: grid;
  grid-template-columns: 15% 25% 50% 10%;
  padding-left: 15px;
  align-items: center;
  & div {
    font-size: 16px;
  }
  & div.id {
    text-align: left;
  }
`;
