import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import success from '../../assets/Success.svg';
import payjimLogo from '../../assets/Logo.svg';
import { useEffect } from 'react';
import Api from '../../utils/Api';

export default function ConsentSuccessPage() {
  let [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate('/properties');
  };
  useEffect(() => {
    if (code) {
      const fetchAccessToken = async () => {
        await Api.post(
          '/auth/generate-first-access-token',
          { authCode: code },
          { headers: { token: localStorage.getItem('token') } }
        );
      };
      fetchAccessToken()
        .then(() => console.log('Verification Successful'))
        .catch((err) => console.log(err));
    }
  }, [code]);

  return (
    <StyledContainer>
      <header>
        <StyledH1>
          <StyledLogoImg src={payjimLogo} alt="payjim logo" />
        </StyledH1>
      </header>
      <div align="center">
        <img style={{ marginTop: '2em' }} src={success} alt="arrow-right" />
        <div style={{ fontSize: '50px' }}>Success.</div>
      </div>
      <div style={{ width: '70%' }}>
        <PrimaryButton onClick={handleButtonClick}>Continue</PrimaryButton>
      </div>
    </StyledContainer>
  );
}
const StyledContainer = styled.div`
  padding: 3em 1em;
  margin: 0 auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 2em;
  align-items: center;
  justify-content: center;
  max-width: 700px;

  ::after {
    content: '';
    display: block;
    width: 30%;
    height: 4px;
    border-radius: 10px;
    bottom: 0;
    position: absolute;
  }

  @media (min-width: 600px) {
    gap: 4em;
  }
`;

const StyledLogoImg = styled.img`
  width: 244px;
  height: 86px;
`;

const StyledH1 = styled.h1`
  font-family: var(--heyjack-font);
  font-size: 36px;
  font-weight: 400;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5em;

  padding-bottom: 1em;

  @media (min-width: 550px) {
    flex-direction: row;
    font-size: 40px;
    padding-bottom: 0;
  }

  @media (min-width: 700px) {
    font-size: 50px;
  }

  @media (min-width: 900px) {
    font-size: 70px;
  }
`;
const PrimaryButton = styled.div`
  padding: 0.3em 1em;
  border: none;
  border-radius: 10px;
  color: var(--pj-white);
  font-size: 16px;
  font-weight: 500;
  background-color: var(--pj-orange);
  box-shadow: 0 4px 5px #ddd;
  width: 100%;
  margin-bottom: 4em;
  display: flex;
  align-items: center;
  justify-content: center;

  :hover,
  :focus {
    background-color: #f86504;
    transform: scale(1.03);
  }

  @media (min-width: 800px) {
    padding: 0.8em 4em;
  }
`;
