import React, { useState } from 'react';
import styled from 'styled-components';
import { FaCheckCircle } from 'react-icons/fa';
const DropdownWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`;

const DropdownButton = styled.button`
  width: 100%;
  height: 55px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 10px;
  color: #555;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;

  &:focus {
    outline: none;
  }
`;

const DropdownPlaceholder = styled.span`
  color: #999;
`;

const ArrowIcon = styled.span`
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #999;
  margin-right: 10px;
`;

const DropdownContent = styled.div`
  position: absolute;
  z-index: 1;
  top: 80px;
  width: 100%;
  background-color: white;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 10px 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
`;

const DropdownItem = styled.a`
  color: #555;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;

  &:hover {
    background-color: #f1f1f1;
  }

  &:active {
    background-color: #ddd;
  }
`;

const Dropdown = ({ options, selectedOption, setSelectedOption, available }) => {
  const [open, setOpen] = useState(false);

  const checkWasCreated = (id) => {
    if (available && available.length > 0) {
      const filtered = available.filter((property) => property.propertyId === id)[0];
      return !!filtered;
    } else return null;
  };
  const handleButtonClick = () => {
    setOpen(!open);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setOpen(false);
  };

  return (
    <DropdownWrapper>
      <DropdownButton onClick={handleButtonClick}>
        {selectedOption ? (
          selectedOption.name
        ) : (
          <DropdownPlaceholder>Select an account</DropdownPlaceholder>
        )}
        <ArrowIcon />
      </DropdownButton>
      {open && (
        <DropdownContent>
          {options.map((option) => (
            <DropdownItem key={option.id} onClick={() => handleOptionClick(option)}>
              {option.name}{' '}
              {checkWasCreated(option.id) ? (
                <FaCheckCircle style={{ marginLeft: 2, marginTop: 5 }} color="green" size={18} />
              ) : null}
            </DropdownItem>
          ))}
        </DropdownContent>
      )}
    </DropdownWrapper>
  );
};

export default Dropdown;
