import styled from 'styled-components/macro';
import payjimLogo from '../../assets/Logo.svg';
import Dropdown from '../../global/components/DropDown';
import { useState } from 'react';
import Api from '../../utils/Api';

export default function OnboardingPage() {
  const [selectedOption, setSelectedOption] = useState(null);
  const handleContinue = async () => {
    try {
      const res = await Api.get(`/auth/generate-identity-link`, {
        headers: {
          token: localStorage.getItem('token')
        }
      });
      window.location.href = res.data;
    } catch (err) {
      console.log('Error', err);
    }
  };
  return (
    <div>
      <StyledContainer>
        <header>
          <StyledH1>
            <StyledLogoImg src={payjimLogo} alt="payjim logo" />
          </StyledH1>
        </header>
        <div style={{ width: '65%', fontWeight: 'lighter' }}>
          <div style={{ marginBottom: '5%' }}>
            <div
              style={{
                paddingBottom: '0.6em'
              }}
            >
              Connect to PayJIM
            </div>
            <div
              style={{
                borderBottom: '3px solid var(--pj-orange)',
                width: '10%'
              }}
            ></div>
            <div
              style={{
                paddingTop: '1em'
              }}
            >
              Please choose your property management system (PMS) from the list below and click
              continue
            </div>
          </div>
          <Dropdown
            options={[{ id: 'apaleo', name: 'Apaleo' }]}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
        </div>
        <PrimaryButtonProperty disabled={!selectedOption} onClick={handleContinue}>
          Continue
        </PrimaryButtonProperty>
      </StyledContainer>
    </div>
  );
}

const StyledContainer = styled.div`
  padding: 3em 1em;
  margin: 0 auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 2em;
  align-items: center;
  justify-content: center;
  max-width: 700px;

  ::after {
    content: '';
    display: block;
    width: 30%;
    height: 4px;
    border-radius: 10px;
    bottom: 0;
    position: absolute;
  }

  @media (min-width: 600px) {
    gap: 4em;
  }
`;
const StyledH1 = styled.h1`
  font-family: var(--heyjack-font);
  font-size: 36px;
  font-weight: 400;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5em;

  padding-bottom: 1em;

  @media (min-width: 550px) {
    flex-direction: row;
    font-size: 40px;
    padding-bottom: 0;
  }

  @media (min-width: 700px) {
    font-size: 50px;
  }

  @media (min-width: 900px) {
    font-size: 70px;
  }
`;

const StyledLogoImg = styled.img`
  width: 244px;
  height: 86px;
`;
const PrimaryButtonProperty = styled.button`
  background-color: ${(props) => (props.disabled ? 'var(--pj-disabled)' : 'var(--pj-orange)')};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  padding: 0.3em 0.4em;
  border: none;
  border-radius: 10px;
  color: var(--pj-white);
  font-size: 16px;
  font-weight: 500;
  box-shadow: 0 4px 5px #ddd;

  :hover,
  :focus {
    background-color: #f86504;
    transform: scale(1.03);
  }

  @media (min-width: 800px) {
    padding: 0.6em 3em;
  }
`;
