import styled from 'styled-components/macro';
import { Form, Formik, ErrorMessage, Field } from 'formik';
import * as Yup from 'yup';

import { useNavigate } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import Api from '../../../utils/Api';

export default function SignInForm() {
  const navigate = useNavigate();

  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      validationSchema={Yup.object({
        email: Yup.string().email('Invalid Email').required('Email Required'),
        password: Yup.string().required('Password Required')
      })}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        setSubmitting(true);
        try {
          const res = await Api.post('/auth/login', values);
          if (res.status === 200) {
            NotificationManager.success('Login Success');
            localStorage.setItem('token', res.data.token);
            if (res.data.firstTime === true) {
              navigate('/onboarding');
            }
            if (res.data.firstTime === false) {
              navigate('/properties');
            }
          } else {
            if(res.response.data) {
              NotificationManager.error(res.response.data,'Login failed');
            }
          }
        } catch (err) {
          console.log('Unknown error: ', err);
          setErrors(true);
        }
      }}
    >
      {({ isSubmitting, isValid, dirty }) => {
        return (
          <StyledForm>
            <div>
              <StyledLabel htmlFor="email">
                <StyledInput id="email" type="email" name="email" required />
                <span>Email</span>
              </StyledLabel>
              <StyledError component="div" name="email" />
            </div>

            <div>
              <StyledLabel htmlFor="password">
                <StyledInput
                  id="password"
                  type="password"
                  name="password"
                  autoComplete="current-password"
                  required
                />
                <span>Passwort</span>
              </StyledLabel>
              <StyledError component="div" name="password" />
            </div>
            <StyledButtonsContainer>
              <PrimaryButtonContinue type="submit" disabled={isSubmitting || !isValid || !dirty}>
                Continue
              </PrimaryButtonContinue>
              <button
                style={{
                  color: 'black',
                  border: 'none',
                  background: 'inherit'
                }}
                onClick={() => navigate('/forget-password')}
              >
                Forgot my password
              </button>
            </StyledButtonsContainer>
          </StyledForm>
        );
      }}
    </Formik>
  );
}

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: 80%;
  margin-bottom: 4em;
  font-size: 16px;
`;

const StyledLabel = styled.label`
  position: relative;
`;

const StyledInput = styled(Field)`
  border: ${(props) =>
    props.hasError ? '1px solid var(--pj-failed)' : '1px solid var(--pj-disabled)'};
  border-radius: 10px;
  padding: 1.7em 2em;
  color: var(--pj-disabled);
  width: 100%;
  background-color: var(--pj-white);

  + span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: var(--pj-disabled);
    padding: 0 0.5em;
    background-color: var(--pj-white);
    transition: all 0.1s ease-in-out;
  }

  :focus + span,
  :valid + span {
    top: -50%;
  }

  @media (min-width: 550px) {
    + span {
      left: 0.5em;
      transform: translate(0, -50%);
    }
  }
`;

const StyledError = styled(ErrorMessage)`
  color: var(--pj-failed);
  text-align: center;
  padding-top: 0.75em;
  padding-left: 0.25em;

  @media (min-width: 550px) {
    text-align: left;
  }
`;

const StyledButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  align-items: center;
  justify-content: center;
  margin-top: 1.5em;

  @media (min-width: 500px) {
    flex-direction: row;
  }
`;

const PrimaryButtonContinue = styled.button`
  background-color: ${(props) => (props.disabled ? 'var(--pj-disabled)' : 'var(--pj-orange)')};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  padding: 0.3em 0.4em;
  border: none;
  border-radius: 10px;
  color: var(--pj-white);
  font-size: 16px;
  font-weight: 500;
  box-shadow: 0 4px 5px #ddd;

  :hover,
  :focus {
    background-color: #f86504;
    transform: scale(1.03);
  }

  @media (min-width: 800px) {
    padding: 0.6em 3em;
  }
`;
