import { toPng } from 'html-to-image';
import { useRef, useState } from 'react';

import { SecondaryButton } from '../../../global/Button.style';
import QRImage from './QRImage';

export default function QRContainer({
  uniqueId,
  propertyId,
  selectedItemId,
  selectedItemName,
  hotelImage,
  color
}) {
  const templateRef = useRef(null);
  const qrCodeRef = useRef(null);

  const handleDownload = (elementRef, fileName) => {
    if (elementRef.current) {
      return toPng(elementRef.current)
        .then((dataUrl) => {
          const link = document.createElement('a');
          link.download = fileName || 'image.png';
          link.href = dataUrl;
          link.click();
        })
        .catch((error) => {
          console.error('Error <gene></gene>rating png for download:', error);
        });
    }
  };

  return (
    <div>
      <QRImage
        uniqueId={uniqueId}
        propertyId={propertyId}
        selectedItemId={selectedItemId}
        selectedItemName={selectedItemName}
        hotelImage={hotelImage}
        color={color}
        templateRef={templateRef}
        qrCodeRef={qrCodeRef}
      />
      <div
        style={{
          display: 'flex',
          gap: '1rem',
          marginTop: '1rem'
        }}>
        <DownloadButton
          onDownload={() => handleDownload(templateRef, selectedItemName+'-QR-Template.png')}
          text="Download Template"
          color={color}
        />
        <DownloadButton
          onDownload={() => handleDownload(qrCodeRef, selectedItemName+'-QR-Code.png')}
          text="Download QR-Code"
          color={color}
        />
      </div>
    </div>
  );
}

const DownloadButton = ({ onDownload, text, color }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const handleClick = async () => {
    setIsDownloading(true);
    try {
      await onDownload();
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <SecondaryButton color={color} onClick={handleClick} disabled={isDownloading}>
      {isDownloading ? 'Downloading...' : text}
    </SecondaryButton>
  );
};
