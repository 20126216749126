import styled from 'styled-components/macro';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ImageUpload from './components/ImageUpload';
import phone from '../../assets/Phone.svg';
import { SketchPicker } from 'react-color';
import { FaArrowCircleLeft } from 'react-icons/fa';
import logo from '../../assets/Logo.svg';
import Api from '../../utils/Api';
export default function CustomizePage() {
  const [image, setImage] = useState(null);
  const [primaryColor, setPrimaryColor] = useState('#f86504');
  const navigate = useNavigate();
  const { propertyId } = useParams();
  useEffect(() => {
    getHotelLogoAndColor()
      .then(() => console.log('Logo and Color fetched'))
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    convertImageToBase64(logo);
  }, []);

  const convertImageToBase64 = (imgUrl) => {
    const image = new Image();
    image.crossOrigin = 'anonymous';
    image.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.height = image.naturalHeight;
      canvas.width = image.naturalWidth;
      ctx.drawImage(image, 0, 0);
      const dataUrl = canvas.toDataURL();
      setImage(dataUrl);
    };
    image.src = imgUrl;
  };

  const getHotelLogoAndColor = async () => {
    try {
      const { data } = await Api.get(`/hotel/information?propertyId=${propertyId}`, {
        headers: {
          token: localStorage.getItem('token')
        }
      });
      if (data.color !== null) {
        setPrimaryColor(data.color);
      }
      if (data.logo !== null) {
        setImage('data:image/png;base64,' + data.logo);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleContinue = async (event) => {
    event.preventDefault();
    try {
      const res = await Api.post(
        `/hotel/${propertyId}/logo`,
        {
          logo: image,
          color: primaryColor
        },
        {
          headers: {
            token: localStorage.getItem('token')
          }
        }
      );
      if (res.status === 200) navigate(`/${propertyId}/hotel/rooms`);
    } catch (error) {
      console.log(error);
    }
  };
  const handlePrimaryColor = (color) => {
    setPrimaryColor(color.hex);
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div style={{ position: 'relative' }}>
      <FaArrowCircleLeft
        style={{ marginTop: '2em' }}
        onClick={handleBack}
        size={64}
        color={primaryColor}
      />
      <div
        style={{
          paddingLeft: '15%',
          marginBottom: '5%'
        }}
      >
        <div style={{ fontSize: '46px' }}>Welcome to payjim</div>
        <div
          style={{
            color: primaryColor,
            fontSize: '32px'
          }}
        >
          Register an account
        </div>
      </div>
      <StyledContainer>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            gap: '2em',
            fontWeight: 'lighter',
            marginBottom: '5%'
          }}
        >
          <div style={{ flex: '1' }}>
            <h2
              style={{
                fontWeight: 'normal',
                paddingBottom: '0.6em'
              }}
            >
              Customize your app
            </h2>
            {primaryColor && (
              <div
                style={{
                  borderBottom: '3px solid ' + primaryColor,
                  width: '10%'
                }}
              ></div>
            )}

            <div
              style={{
                paddingTop: '1em'
              }}
            >
              Enter your primary colour to see how your application will look like for your guests
              or you can keep the defaults as below.
            </div>
            <div
              style={{
                paddingTop: '1em',
                width: '100%'
              }}
            >
              {primaryColor && (
                <SketchPicker
                  style={{
                    paddingTop: '1em',
                    width: 260
                  }}
                  color={primaryColor}
                  onChangeComplete={handlePrimaryColor}
                />
              )}
            </div>
            <div
              style={{
                paddingTop: '1em',
                width: '100%'
              }}
            >
              <ImageUpload setImage={setImage} image={image} />
            </div>
            <div align="right" style={{ marginTop: '15%', marginBottom: '20%' }}>
              {primaryColor && (
                <PrimaryButtonContinue
                  color={primaryColor}
                  disabled={!image}
                  onClick={handleContinue}
                >
                  Continue
                </PrimaryButtonContinue>
              )}
            </div>
          </div>
          <div style={{ flex: '1' }}></div>
        </div>
        <div>
          <PhoneImage src={phone} alt="Phone" />
        </div>
      </StyledContainer>
    </div>
  );
}

//      STYLES

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 7em;

  ::after {
    content: '';
    display: block;
    width: 30%;
    height: 4px;
    border-radius: 10px;
    // background: #000000;
    bottom: 0;
    position: absolute;
  }
  @media (min-width: 600px) {
    gap: 4em;
  }
`;

const PrimaryButtonContinue = styled.button`
  background-color: ${(props) => (props.disabled ? 'var(--pj-disabled)' : props.color)};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  padding: 0.3em 0.4em;
  border: none;
  border-radius: 10px;
  color: var(--pj-white);
  font-size: 16px;
  font-weight: 500;
  box-shadow: 0 4px 5px #ddd;

  :hover,
  :focus {
    background-color: #f86504;
    transform: scale(1.03);
  }

  @media (min-width: 800px) {
    padding: 0.6em 3em;
  }
`;

const PhoneImage = styled.img`
  width: 296px;
  position: absolute;
  top: 12%;
  right: 10%;

  @media only screen and (max-width: 970px) {
    height: 50%;
    top: 25%;
    left: 50%;
  }
`;
