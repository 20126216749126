import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { NotificationManager } from 'react-notifications';
import styled from 'styled-components/macro';
import { useState } from 'react';
import Api from '../../../utils/Api';

export default function ForgetPasswordForm() {
  const [success, setSuccess] = useState(false);
  return (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={Yup.object({
        email: Yup.string().email('Invalid Email').required('Eine E-Mail ist erforderlich')
      })}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        setSubmitting(true);
        try {
          const res = await Api.post('/auth/reset-password', values);
          if (res) {
            NotificationManager.success('Email sent, please check also your spam folder');
            setSuccess(true);
          }
        } catch (err) {
          console.log('Unknown error: ', err);
          NotificationManager.success('Email not found');
          setErrors(err);
        }
      }}
    >
      {({ isSubmitting, isValid, dirty }) => {
        return (
          <StyledForm>
            <div>
              <StyledLabel htmlFor="email">
                <StyledInput id="email" type="email" name="email" required />
                <span> Your Email</span>
              </StyledLabel>
              <StyledError component="div" name="email" />
            </div>

            <StyledButtonsContainer>
              <PrimaryButtonContinue type="submit" disabled={isSubmitting || !isValid || !dirty}>
                Send
              </PrimaryButtonContinue>
            </StyledButtonsContainer>
            {success && (
              <div>
                <StyledLink href="/">Go back to login</StyledLink>
              </div>
            )}
          </StyledForm>
        );
      }}
    </Formik>
  );
}
const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: 75%;
  margin-bottom: 4em;
  font-size: 16px;
`;

const StyledLabel = styled.label`
  position: relative;
`;

const StyledInput = styled(Field)`
  border: ${(props) =>
    props.hasError ? '2px solid var(--pj-failed)' : '2px solid var(--pj-disabled)'};
  border-radius: 10px;
  padding: 1.7em 2em;
  color: var(--pj-disabled);
  width: 100%;
  background-color: var(--pj-white);

  + span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: var(--pj-disabled);
    padding: 0 0.5em;
    background-color: var(--pj-white);
    transition: all 0.1s ease-in-out;
  }

  :focus + span,
  :valid + span {
    top: -50%;
  }

  @media (min-width: 550px) {
    + span {
      left: 0.5em;
      transform: translate(0, -50%);
    }
  }
`;

const StyledError = styled(ErrorMessage)`
  color: var(--pj-failed);
  text-align: center;
  padding-top: 0.75em;
  padding-left: 0.25em;

  @media (min-width: 550px) {
    text-align: left;
  }
`;

const StyledButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  align-items: center;
  justify-content: center;
  margin-top: 1.5em;

  @media (min-width: 500px) {
    flex-direction: row;
  }
`;
const PrimaryButtonContinue = styled.button`
  background-color: ${(props) => (props.disabled ? 'var(--pj-disabled)' : 'var(--pj-orange)')};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  padding: 0.3em 0.4em;
  border: none;
  border-radius: 10px;
  color: var(--pj-white);
  font-size: 16px;
  font-weight: 500;
  box-shadow: 0 4px 5px #ddd;

  :hover,
  :focus {
    background-color: #f86504;
    transform: scale(1.03);
  }

  @media (min-width: 800px) {
    padding: 0.6em 3em;
  }
`;
const StyledLink = styled.a`
  color: var(--pj-orange);
  text-decoration: underline;

  :hover {
    text-decoration: none;
  }
`;
