import styled from 'styled-components/macro';
import logo from '../../assets/Logo.svg';
import SignInForm from './components/SignInForm';

export default function LoginPage() {
  return (
    <StyledContainer>
      <header>
        <StyledH1>
          <StyledLogoImg src={logo} alt="payjim logo" />
        </StyledH1>
      </header>

      <SignInForm />

      <div>
        <span>First time here? - </span>{' '}
        <StyledLink href="/register">Register a new account</StyledLink>
      </div>
    </StyledContainer>
  );
}

//      STYLES

const StyledContainer = styled.div`
  padding: 3em 1em;
  margin: 0 auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 2em;
  align-items: center;
  justify-content: center;
  max-width: 700px;

  ::after {
    content: '';
    display: block;
    width: 30%;
    height: 4px;
    border-radius: 10px;
    bottom: 0;
    position: absolute;
  }

  @media (min-width: 600px) {
    gap: 4em;
  }
`;

const StyledH1 = styled.h1`
  font-family: var(--heyjack-font);
  font-size: 36px;
  font-weight: 400;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5em;

  padding-bottom: 1em;

  @media (min-width: 550px) {
    flex-direction: row;
    font-size: 40px;
    padding-bottom: 0;
  }

  @media (min-width: 700px) {
    font-size: 50px;
  }

  @media (min-width: 900px) {
    font-size: 70px;
  }
`;

const StyledLogoImg = styled.img`
  width: 244px;
  height: 86px;
`;
const StyledLink = styled.a`
  color: var(--pj-orange);
  text-decoration: underline;

  :hover {
    text-decoration: none;
  }
`;
