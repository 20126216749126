import React from 'react';
import styled from 'styled-components';
import imageLogo from '../../../assets/ImageLogo.svg';
import star from '../../../assets/Star.svg';

const ImageUploadWrapper = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 195px;
  background-color: #f8f8f8;
  position: relative;
  cursor: pointer;
`;

const Star = styled.img`
  position: absolute;
  top: -24px;
  right: 10px;
  width: 14px;
  height: 62px;
  margin-top: 20px;
`;

const Logo = styled.img`
  width: 51px;
  height: 44px;
  margin-top: 20px;
`;

const CompanyLogo = styled.img`
  height: 60%;
  margin-top: 20px;
`;

const UploadText = styled.p`
  font-size: 16px;
  margin-bottom: 10px;
  opacity: 0.3;
`;

const HiddenInput = styled.input`
  display: none;
`;

const ImageUpload = ({ setImage, image }) => {
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file instanceof File) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const image = new Image();
        image.src = reader.result;
        image.onload = () => {
          const {
            height,
            width
          } = image;
          if (width /height  < 1.33 || width/height > 1.77 ) {
            alert("Image should be the minimum aspect ratio of 4:3 and maximum 16:9");
            return false;
          }
          else{
            if(file.size >1048576 ){
              alert("Image should be max 1MB size");
              return false;
            }
            else
              setImage(reader.result);
          }
        };
      };
    } else {
      alert('Selected file is not a valid image object.');
      return false;
    }
  };

  return (
    <ImageUploadWrapper>
      <Star src={star} alt="Star" />
      {image ? (
        <CompanyLogo src={image} alt="Logo" />
      ) : (
        <div align="center">
          <UploadText>Upload your logo</UploadText>
          <Logo src={imageLogo} alt="Image" />
        </div>
      )}
      <HiddenInput id="image-input" type="file" onChange={handleFileChange} />
    </ImageUploadWrapper>
  );
};

export default ImageUpload;
