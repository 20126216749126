import QR from 'react-qr-code';

export default function QRImage({
  uniqueId,
  propertyId,
  hotelImage,
  selectedItemId,
  selectedItemName,
  color,
  templateRef,
  qrCodeRef
}) {
  const qrCodeURL = `https://guest.payjim.app/hotel/${uniqueId}/property/${propertyId}/${selectedItemId}`;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        margin: '0 auto',
        marginTop: '1rem'
      }}>
      <div
        ref={templateRef}
        style={{
          fontFamily: 'Montserrat, sans-serif',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '1.5rem 0',
          gap: '1.5rem',
          borderRadius: '0.75rem',
          border: `3px solid ${color || '#EE7B30'}`,
          boxSizing: 'border-box',
          textAlign: 'center',
          fontSize: '12px',
          backgroundColor: '#ffffff',
          color: '#fbfafa',
          width: '32rem',
          overflow: 'hidden'
        }}>
        <img
          align="middle"
          style={{ height: '8rem', padding: '1rem' }}
          src={'data:image/png;base64,' + hotelImage}
          alt="Logo"
        />
        <div
          style={{
            textAlign: 'center',
            color: '#000',
            fontWeight: 600,
            fontSize: '2.75rem',
            padding: '0 1rem',
            marginBottom: '8rem'
          }}>
          Check out here!
        </div>
        <div
          style={{
            background: color || '#EE7B30',
            width: '100%',
            padding: '0 1rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
          <div
            style={{
              marginTop: '-6rem',
              backgroundColor: '#ffffff',
              border: `3px solid ${color || '#EE7B30'}`,
              borderRadius: '0.75rem',
              padding: '0.5rem'
            }}>
            <QR ref={qrCodeRef} size={336} value={qrCodeURL} />
          </div>
          <div
            align="center"
            style={{
              color: color === '#ffffff' ? 'black' : 'white',
              fontWeight: 500,
              fontSize: '1.5rem',
              display: 'flex',
              flexDirection: 'column',
              gap: '0.5rem',
              padding: '1.5rem 0',
              marginBottom: '0.5rem'
            }}>
            <span>Pay your bill by</span>
            <span>scanning the QR Code.</span>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: color ? color : '#EE7B30',
            borderRadius: 45,
            maxWidth: 'max-content',
            margin: '1rem auto',
            marginBottom: '1.5rem'
          }}>
          <div
            style={{
              fontSize: 22,
              color: color || '#EE7B30',
              background: '#FBFAFA',
              border: `2px solid ${color || '#EE7B30'}`,
              borderRadius: 45,
              padding: '8px 16px 8px 16px'
            }}>
            Room
          </div>
          <div
            style={{
              fontSize: 24,
              fontWeight: 600,
              color: 'white',
              maxWidth: 'max-content',
              padding: '0.5rem 1rem 0.5rem 0.5rem'
            }}>
            {selectedItemName}
          </div>
        </div>
      </div>
    </div>
  );
}
