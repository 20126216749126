import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

// import AlternativeRegistration from "../forms/AlternativeRegistration";
import RegistrationForm from './components/RegistrationForm';
import backArrow from '../../assets/ArrowRight.svg';

export default function RegisterPage() {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };
  return (
    <div style={{ position: 'relative' }}>
      <img style={{ marginTop: '2em' }} onClick={handleBack} src={backArrow} alt="arrow-right" />
      <div
        style={{
          paddingLeft: '15%',
          marginBottom: '5%'
        }}
      >
        <div style={{ fontSize: '46px' }}>Welcome to payjim</div>
        <div
          style={{
            color: 'var(--pj-orange)',
            fontSize: '32px'
          }}
        >
          Register an account
        </div>
      </div>
      <StyledContainer>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            gap: '2em',
            fontWeight: 'lighter',
            marginBottom: '5%'
          }}
        >
          <div style={{ flex: '1' }}>
            <h2
              style={{
                fontWeight: 'normal',
                paddingBottom: '0.6em'
              }}
            >
              Choose your credentials
            </h2>
            <div
              style={{
                borderBottom: '3px solid var(--pj-orange)',
                width: '4%'
              }}
            ></div>
            <div
              style={{
                paddingTop: '1em'
              }}
            >
              Please create a payjim account. This will allow you to access all relevant data later
              on.
            </div>

            <RegistrationForm />
            {/* <AlternativeRegistration /> */}
          </div>
        </div>
      </StyledContainer>
    </div>
  );
}

//      STYLES
const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0em 7em;

  ::after {
    content: '';
    display: block;
    width: 30%;
    height: 4px;
    border-radius: 10px;
    // background: #000000;
    bottom: 0;
    position: absolute;
  }
  @media (min-width: 600px) {
    gap: 4em;
  }
`;
