import { useNavigate, useSearchParams } from 'react-router-dom';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { NotificationManager } from 'react-notifications';
import styled from 'styled-components/macro';
import showIcon from '../../../assets/Show.svg';
import React from 'react';
import Api from '../../../utils/Api';

export default function ChangePasswordForm() {
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const user = searchParams.get('user');
  const [showPassword, setShowPassword] = React.useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <Formik
      initialValues={{ password: '', confirmPassword: '' }}
      validationSchema={Yup.object({
        password: Yup.string()
          .required('Password Required')
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&.*])(?=.{8,})/,
            'Must Contain Minimum 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
          ),
        confirmPassword: Yup.string()
          .oneOf([Yup.ref('password'), null], 'Passwords must match')
          .required('Confirm Password is Required')
      })}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        setSubmitting(true);
        try {
          const res = Api.post('/auth/change-password', {
            user: user,
            password: values.password
          });
          if (res) {
            NotificationManager.success('Password Changed, please login');
            navigate('/');
          }
        } catch (err) {
          console.log('Unknown error: ', err);
          setErrors(err);
        }
      }}
    >
      {({ isSubmitting, isValid, dirty }) => {
        return (
          <StyledForm>
            <h2
              style={{
                fontWeight: 'normal',
                marginBottom: '-1em'
              }}
            >
              Please enter your new password.
              {
                <div>
                  <br /> <br />
                </div>
              }
            </h2>
            <div>
              Your Password must Contain 8 Characters, One Uppercase, One Lowercase, One Number and
              One Special Case Character
            </div>
            <div style={{ position: 'relative' }}>
              <Field
                name="password"
                autoComplete={'new-password'}
                type={showPassword ? 'text' : 'password'}
                as={InputField}
                placeholder="Password"
              />
              <FirstIconWrapper onClick={togglePasswordVisibility}>
                <img
                  style={{ marginTop: '2em' }}
                  onClick={togglePasswordVisibility}
                  src={showIcon}
                  alt="arrow-right"
                />
              </FirstIconWrapper>
              <ErrorMessage name="password" component={ErrorText} />
            </div>
            <div style={{ position: 'relative' }}>
              <Field
                name="confirmPassword"
                autoComplete={'new-password'}
                type={showPassword ? 'text' : 'password'}
                as={InputField}
                placeholder="Confirm Password"
              />
              <SecondIconWrapper onClick={togglePasswordVisibility}>
                <img
                  style={{ marginTop: '2em' }}
                  onClick={togglePasswordVisibility}
                  src={showIcon}
                  alt="arrow-right"
                />
              </SecondIconWrapper>
              <ErrorMessage name="confirmPassword" component={ErrorMessageWrapper} />
            </div>

            <StyledButtonsContainer>
              <PrimaryButtonContinue type="submit" disabled={isSubmitting || !isValid || !dirty}>
                Send
              </PrimaryButtonContinue>
            </StyledButtonsContainer>
          </StyledForm>
        );
      }}
    </Formik>
  );
}
const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: 75%;
  margin-bottom: 4em;
  font-size: 16px;
`;
const FirstIconWrapper = styled.div`
  position: absolute;
  top: 25%;
  right: 5%;
  transform: translateY(-50%);
  cursor: pointer;
`;

const SecondIconWrapper = styled.div`
  position: absolute;
  top: 24%;
  right: 5%;
  transform: translateY(-50%);
  cursor: pointer;
`;

const ErrorMessageWrapper = styled.div`
  color: red;
`;
const StyledButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  align-items: center;
  justify-content: center;
  margin-top: 1.5em;

  @media (min-width: 500px) {
    flex-direction: row;
  }
`;
const PrimaryButtonContinue = styled.button`
  background-color:  {"var(--pj-orange)"};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  padding: 0.3em 0.4em;
  border: none;
  border-radius: 10px;
  color: var(--pj-orange);
  font-size: 16px;
  font-weight: 500;
  box-shadow: 0 4px 5px #ddd;

  :hover,
  :focus {
    background-color: #f86504;
    color: var(--pj-white);
    transform: scale(1.03);
  }

  @media (min-width: 800px) {
    padding: 0.6em 3em;
  }
`;
const InputField = styled(Field)`
  height: 67px;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #ccc;
  font-size: 16px;
  width: 100%;
  margin-bottom: 3%;
`;
const ErrorText = styled.div`
  color: red;
  margin-top: 5px;
`;
