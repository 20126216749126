import styled from 'styled-components/macro';
import ReactDOMServer from 'react-dom/server';
import FileDownload from 'js-file-download';
import RoomItem from './RoomItem';
import QRImage from './QRImage';
import { SecondaryButton } from '../../../global/Button.style';
import { useNavigate } from 'react-router-dom';
import Api from '../../../utils/Api';
import { useState } from 'react';

export default function RoomsList({
  rooms,
  uniqueId,
  selectedRoom,
  setSelectedRoom,
  setSelectedRoomName,
  color,
  logo
}) {
  const navigate = useNavigate();
  const [isDownloadStarted, setDownloadStarted] = useState(null);
  const handleGoBack = () => {
    navigate('/properties');
  };
  const handleLogout = () => {
    localStorage.removeItem('expires_in');
    localStorage.removeItem('token');
    localStorage.removeItem('access_token');
    localStorage.removeItem('email');
    navigate('/');
  };
  //download all

  const generateQRHtml = (room) => {
    return (
      <QRImage
        uniqueId={uniqueId}
        propertyId={room.property.id}
        color={color}
        hotelImage={logo}
        selectedItemId={room.id}
        selectedItemName={room.name}
      />
    );
  };

  const handlePdfRequest = async (html) => {
    setDownloadStarted(true);
    await Api.post('/pdf/pdfLink', { html }, { headers: { 'content-type': 'application/json' } })
      .then((res) => {
        if (res.data) {
          Api.get(res.data, {
            responseType: 'blob'
          }).then((res) => {
            FileDownload(res.data, `QRCodes.pdf`);
            setDownloadStarted(false);
          });
        } else {
          console.log('pdf link not sent');
        }
      })
      .catch((err) => {
        setDownloadStarted(false);
        console.log(err);
      });
  };

  const handleDownloadAll = () => {
    if (!rooms) {
      return;
    }
    const roomsHtml = [];
    rooms?.forEach((room) => {
      const html = (
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '2em',
            pageBreakAfter: 'always'
          }}>
          {generateQRHtml(room)}
        </div>
      );
      const htmlString = ReactDOMServer.renderToString(html);
      const quotationReplaceHtml = htmlString.replaceAll(`"`, `'`);
      roomsHtml.push(quotationReplaceHtml);
    });

    // const roomsHtml = rooms.map((room) => generateQRHtml(room));
    handlePdfRequest(roomsHtml.join('')).then(() => console.log('PDF Request Completed'));
  };

  return (
    <ListWrapper>
      <HeadWrapper>
        <span># ID</span>
        <span>Name</span>
        <div>
          <SecondaryButton color={color} onClick={handleDownloadAll}>
            Download All
          </SecondaryButton>
        </div>
      </HeadWrapper>
      <RoomsWrapper>
        {rooms?.map((room, i) => {
          // setSelectedRoomName
          return (
            <RoomItem
              key={i.toString()}
              room={room}
              color={color}
              selectedRoom={selectedRoom}
              setSelectedRoom={setSelectedRoom}
              setSelectedRoomName={setSelectedRoomName}
            />
          );
        })}
      </RoomsWrapper>
      <div>
        <SecondaryButton
          color={color}
          onClick={handleGoBack}
          className="flex flex-col justify-center items-center text-white rounded-xl py-[8px] px-[8%] bg-orange whitespace-nowrap">
          Create QR Codes for Another Property
        </SecondaryButton>
        <SecondaryButton
          color={color}
          onClick={handleLogout}
          className="flex flex-col justify-center items-center text-white rounded-xl py-[8px] px-[8%] bg-orange whitespace-nowrap">
          Logout
        </SecondaryButton>
      </div>
      {isDownloadStarted && (
        <div>
          <StyledSpinner color={color} viewBox="0 0 50 50">
            <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="4" />
          </StyledSpinner>
        </div>
      )}
    </ListWrapper>
  );
}

//          STYLES

const ListWrapper = styled.div`
  text-align: center;
`;
const HeadWrapper = styled.div`
  display: grid;
  grid-template-columns: 23% 40% auto;
  align-items: end;
  grid-gap: 10px;
  padding-top: 2px;
  padding-left: 25px;
  padding-right: 10px;
  & span {
    font-size: 16px;
    padding-bottom: 5px;
  }
`;
const RoomsWrapper = styled.div`
  width: 100%;
  overflow-y: scroll;
  height: 70vh;
  padding: 15px;
`;

const StyledSpinner = styled.svg`
  animation: rotate 2s linear infinite;
  margin: -25px 0 0 -25px;
  width: 50px;
  height: 50px;

  & .path {
    stroke: ${(props) => (props.color ? props.color : '#f86504')};
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;
