import axios from 'axios';
import { PJ_HOTEL_API } from '../configVars';

//Defaults will be combined with the instance
axios.defaults.baseURL = `${PJ_HOTEL_API}`;

//Create Axios Instance
const axiosInstance = axios.create({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=UTF-8'
  }
});

//Add interceptors to instance
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      window.history.push('/');
      localStorage.removeItem('token');
    }
    return error;
  }
);

export default axiosInstance;
