import styled from 'styled-components/macro';

import { useNavigate } from 'react-router-dom';
import backArrow from '../../assets/ArrowRight.svg';
import Dropdown from '../../global/components/DropDown';
import { useEffect, useState } from 'react';
import Api from '../../utils/Api';

export default function PropertiesPage() {
  const navigate = useNavigate();

  let [properties, setProperties] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);

  const [hotels, setHotels] = useState(true);

  const getHotelProperties = async () => {
    try {
      const { data } = await Api.get(`/hotel/information`, {
        headers: {
          token: localStorage.getItem('token')
        }
      });
      setHotels(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const fetchProperties = async () => {
      const res = await Api.get(`/hotel/properties`, {
        headers: { token: localStorage.getItem('token') }
      });
      let propertyNames = [];
      if (res.data) {
        res.data.forEach((data) => {
          propertyNames.push({ id: data.id, name: data.name });
        });
        setProperties(propertyNames);
      }
    };
    fetchProperties()
      .then(() => console.log('PropertiesPage fetch Successful'))
      .catch((err) => console.log(err));
    getHotelProperties().then(() => console.log('Properties Fetched'));
  }, []);

  const handleContinue = async () => {
    navigate(`/${selectedOption.id}/customize`);
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <>
      {properties ? (
        <div>
          <img
            style={{ marginTop: '2em' }}
            onClick={handleBack}
            src={backArrow}
            alt="arrow-right"
          />
          <div
            style={{
              paddingLeft: '15%',
              marginBottom: '5%'
            }}
          >
            <div style={{ fontSize: '46px' }}>Welcome to payjim</div>
            <div
              style={{
                color: 'var(--pj-orange)',
                fontSize: '32px'
              }}
            >
              Register an account
            </div>
          </div>
          <StyledContainer>
            <div style={{ width: '65%', fontWeight: 'lighter' }}>
              <div style={{ marginBottom: '5%' }}>
                <h2
                  style={{
                    fontWeight: 'normal',
                    paddingBottom: '0.6em'
                  }}
                >
                  Hi there!
                </h2>
                <div
                  style={{
                    borderBottom: '3px solid var(--pj-orange)',
                    width: '10%'
                  }}
                ></div>
                <div
                  style={{
                    paddingTop: '1em'
                  }}
                >
                  We have noticed, that you have multiple accounts. Please select from an account
                  from the dropdown menu and hit “continue”.
                </div>
              </div>
              <Dropdown
                options={properties}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                available={hotels}
              />
            </div>
            <div align="center" style={{ marginBottom: '20%' }}>
              <PrimaryButtonProperty disabled={!selectedOption} onClick={handleContinue}>
                Continue
              </PrimaryButtonProperty>
            </div>
          </StyledContainer>
        </div>
      ) : (
        <div>
          <img
            style={{ marginTop: '2em' }}
            onClick={handleBack}
            src={backArrow}
            alt="arrow-right"
          />
          <div
            style={{
              paddingLeft: '15%',
              marginBottom: '5%'
            }}
          >
            <div style={{ fontSize: '46px' }}>Welcome to payjim</div>
            <div
              style={{
                color: 'var(--pj-orange)',
                fontSize: '32px'
              }}
            >
              Register an account
            </div>
          </div>
          <StyledContainer>
            <div style={{ width: '65%', fontWeight: 'lighter' }}>
              <div style={{ marginBottom: '5%' }}>
                <div
                  style={{
                    paddingBottom: '0.6em'
                  }}
                >
                  Hi there!
                </div>
                <div
                  style={{
                    borderBottom: '3px solid var(--pj-orange)',
                    width: '10%'
                  }}
                ></div>
                <div
                  style={{
                    paddingTop: '1em'
                  }}
                >
                  We have noticed, that you have multiple accounts. Please select from an account
                  from the dropdown menu and hit “continue”.
                </div>
              </div>
              404 NO PROPERTIES TO SHOW, Please go to your apaleo account and add your properties.
            </div>
          </StyledContainer>
          <div align="center" style={{ marginTop: '15%', marginBottom: '20%' }}>
            <PrimaryButtonLink href="https://app.apaleo.com/dashboard">Redirect</PrimaryButtonLink>
          </div>
        </div>
      )}
    </>
  );
}

//      STYLES

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: center;
  justify-content: center;
  max-width: 700px;

  ::after {
    content: '';
    display: block;
    width: 30%;
    height: 4px;
    border-radius: 10px;
    // background: #000000;
    bottom: 0;
    position: absolute;
  }
  @media (min-width: 600px) {
    gap: 4em;
  }
`;

const PrimaryButtonProperty = styled.button`
  background-color: ${(props) => (props.disabled ? 'var(--pj-disabled)' : 'var(--pj-orange)')};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  padding: 0.3em 0.4em;
  border: none;
  border-radius: 10px;
  color: var(--pj-white);
  font-size: 16px;
  font-weight: 500;
  box-shadow: 0 4px 5px #ddd;

  :hover,
  :focus {
    background-color: #f86504;
    transform: scale(1.03);
  }

  @media (min-width: 800px) {
    padding: 0.6em 3em;
  }
`;

const PrimaryButtonLink = styled.a`
  background-color: ${(props) => (props.disabled ? 'var(--pj-disabled)' : 'var(--pj-orange)')};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  padding: 0.3em 0.4em;
  border: none;
  border-radius: 10px;
  color: var(--pj-white);
  font-size: 16px;
  font-weight: 500;
  box-shadow: 0 4px 5px #ddd;
  text-decoration: none;

  :hover,
  :focus {
    background-color: #f86504;
    transform: scale(1.03);
  }

  @media (min-width: 800px) {
    padding: 0.6em 3em;
  }
`;
