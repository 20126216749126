import { ThemeProvider } from 'styled-components';
import styled from 'styled-components/macro';
import { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import 'react-notifications/lib/notifications.css';
import LoginPage from './pages/LoginPage/LoginPage';
import RegisterPage from './pages/RegisterPage/RegisterPage';
import RoomsPage from './pages/RoomsPage/RoomsPage';
import { UserContext } from './context/UserContext';
import CustomizePage from './pages/CustomizePage/CustomizePage';
import PropertiesPage from './pages/PropertiesPage/PropertiesPage';
import { NotificationContainer } from 'react-notifications';
import RegistrationSuccessPage from './pages/RegistrationSuccessPage/RegistrationSuccessPage';
import ConsentSuccessPage from './pages/ConsentSuccessPage/ConsentSuccessPage';
import OnboardingPage from './pages/OnboardingPage/OnboardingPage';
import { GlobalStyle, Theme } from './global/GlobalStyle.style';
import ForgetPasswordPage from './pages/ForgetPasswordPage/ForgetPasswordPage';
import ChangePasswordPage from './pages/ChangePasswordPage/ChangePasswordPage';
import ProtectedRoute from './ProtectedRoute';

const AppRouter = () => {
  const [userData, setUserData] = useState();

  return (
    <>
      <GlobalStyle />
      <ThemeProvider theme={Theme}>
        <UserContext.Provider value={{ userData, setUserData }}>
          <Wrapper>
            <Routes>
              <Route path="/" element={<LoginPage />} />
              <Route path="register" element={<RegisterPage />} />
              <Route
                path="auth/success"
                element={<ProtectedRoute component={<ConsentSuccessPage />} />}
              />
              <Route path="forget-password" element={<ForgetPasswordPage />} />
              <Route path="change-password" element={<ChangePasswordPage />} />
              <Route path="registration-success" element={<RegistrationSuccessPage />} />
              <Route
                path="onboarding"
                element={<ProtectedRoute component={<OnboardingPage />} />}
              />
              <Route
                path="properties"
                element={<ProtectedRoute component={<PropertiesPage />} />}
              />
              <Route
                path=":propertyId/customize"
                element={<ProtectedRoute component={<CustomizePage />} />}
              />
              <Route
                path=":propertyId/hotel/rooms"
                element={<ProtectedRoute component={<RoomsPage />} />}
              />
              <Route path="*" element={<>Error 404: Page not found</>} />
            </Routes>
            <NotificationContainer />
          </Wrapper>
        </UserContext.Provider>
      </ThemeProvider>
    </>
  );
};

export default AppRouter;

//            STYLES

const Wrapper = styled.div`
  width: 90%;
  margin: 0 auto;
`;
